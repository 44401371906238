import React from 'react';

function PrivacyPolicy() {
    return (
        <div className="privacy-policy p-10">
            <div className='pb-8'>
            <h5>Privacy Policy for FARMREEL</h5>
            <p>Welcome to FARMREEL, where your privacy is our top priority. Our commitment to protecting your privacy is paramount as we enable you to track earnings, view ads on Reels, and analyze bonus revenue reports conveniently from your mobile device. This Privacy Policy outlines the types of information we collect, how it is used, and the steps we take to protect your data.</p>
            </div>
            <div className='pb-8'>
            <h5>Information Collection and Use</h5>
            <p>FARMREEL collects information to provide better services to all our users. The personal information we collect includes:</p>
            

            <ul>
                <li>Account Information: Your name, email address, and contact details when you register.</li>
                <li>Financial Data: Information related to your earnings and transactions to provide detailed reports.</li>
                <li>Usage Data: Information on how you interact with our app, which helps us tailor and improve our services.</li>
            </ul>

            </div>
            <div className='pb-8'>
            <h5>Data Protection</h5>
            <p>To protect your information, FARMREEL employs state-of-the-art security measures, including:</p>
            <ul>
                <li>Encryption: We use robust encryption technologies to secure data transmissions and storage.</li>
                <li>Access Controls: Your data is accessible only by authorized personnel who are required to keep the information confidential.</li>
                <li>Regular Audits: Our systems are regularly reviewed and updated to fend off potential vulnerabilities.</li>
            </ul>

            </div>

            <div className='pb-8'>
            <h5>Data Sharing and Disclosure</h5>
            <p>FARMREEL does not sell or rent your personal data to third parties. We only share your data under the following circumstances:</p>
            <ul>
                <li>Legal Requirements: If required by law or subpoena, we may disclose your information to comply with legal processes.</li>
                <li>Service Providers: We may share your data with trusted partners who provide services on our behalf, such as data analysis and customer service, under strict confidentiality agreements.</li>
            </ul>
            </div>

            <div className='pb-8'>
            <h5>Your Rights and Choices</h5>
            <p>You have control over your personal information. FARMREEL provides you with the ability to:</p>
            <ul>
                <li>Access and Update: You can review and update your personal information at any time through your account settings.</li>
                <li>Opt-out: You can opt out of certain data collection practices or deactivate your account entirely.</li>
            </ul>
            </div>

            <div className='pb-8'>
            <h5>Changes to Our Privacy Policy</h5>
            <p>We may update this Privacy Policy periodically to reflect changes to our information practices. We will notify you of any significant changes by posting the new policy on our website and updating the date at the top of the policy.</p>
            </div>

            <div className='pb-8'>
            <h5>Contact Us</h5>
            <p>If you have any questions about this Privacy Policy or our treatment of your data, please contact us at <b>heanglyhour99@gmail.com</b>.</p>

            <p>We thank you for choosing FARMREEL and trust that we can provide you with a secure and enjoyable experience.</p>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
