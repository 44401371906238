import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import logo from "./images/logo1.png";
import { Button, Input } from 'rsuite';
import './PricingTable.css'; // Import your CSS for styling
import KHQR from "./images/ABA_QR.png";
import BG_KHQR from "./images/bg-khqr.png";
import 'rsuite/dist/rsuite.min.css';
import axios from 'axios';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, ModalFooter } from '@chakra-ui/react';
import { ChakraProvider } from "@chakra-ui/react"
import QRCode from 'qrcode.react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BakongKHQR, khqrData, IndividualInfo } from "bakong-khqr";
import { Routes, Route } from 'react-router-dom';

const subscriptions = {
    "ONE_MONTH": {
        name: "1 Month Subscription",
        price: 10.00,
    },
    "THREE_MONTHS": {
        name: "3 Months Subscription",
        price: 30.00
    }
}

function PaymentPageComponent() {
    const [showQR, setShowQR] = useState(false);
    const [showInput, setShowInput] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const wsRef = useRef(null);
    const [KHQRStringtest, setKHQRStringtest] = useState('');
    const [key, setKey] = useState("");
    const [md5, setMd5] = useState("");
    const [telegram, settelegram] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [khqrSelected, setKhqrSelected] = useState(false);
    const disableStripe = true;
    const proxyServerUrl = 'https://apifarmreel.mmoshop.me';
    const [paymentSuccess, setPaymentSuccess] = useState(false);

    const handleSubscribeClick = () => {
        if (!isEmailValid) {
            // alert('The email is not valid.');
            return;
        }

        // Hide email input after validating email
        setShowInput(false);

        console.log('Subscribe button clicked with valid email:', email);
        if (selectedPaymentMethod === 'KHQR') {
            setShowQR(true);
            setKhqrSelected(true);
        } else if (selectedPaymentMethod === 'VISA') {
            const amountInCents = selectedPlan.price;
            fetch(`https://apifarmreel.mmoshop.me/api/create-stripe-session?amount=${amountInCents}&email=${email}`, {
                method: 'POST', // Specify the method
            }).then(response => response.json()).then(data => {
                window.location.href = `${data.sessionURL}`;
            }).catch(error => console.error('Error:', error));
        }
    };

    const validateEmail = (email) => {
        return email.includes("@");
    };

    const handleEmailChange = (value) => {
        setEmail(value);
        setIsEmailValid(validateEmail(value));
    };

    const onOpen = () => {
        setIsOpen(true)
    };

    const onClose = () => {
        setIsOpen(false);
        if (wsRef.current) {
            wsRef.current.send("ModalClosed");
            wsRef.current.close();
            wsRef.current.onclose = () => { };
        }
    };

    useEffect(() => {
        if (khqrSelected) {
            setKey('');
            const { BakongKHQR, khqrData } = require("bakong-khqr");
            const optionalData = {
                currency: khqrData.currency.usd,
                amount: selectedPlan.price,
                mobileNumber: "85510888461",
                storeLabel: "FARM REEL",
                terminalLabel: "MMOSHOP_Invoices",
                purposeOfTransaction: "Tool FARMREEL",
                languagePreference: "km",
                merchantNameAlternateLanguage: "Heang",
                merchantCityAlternateLanguage: "Lyhour",
                upiMerchantAccount: "",
            };

            const individualInfo = new IndividualInfo(
                "heang_lyhour@aclb",
                "FARM REEL",
                "PHNOM PENH",
                optionalData
            );

            const khqr = new BakongKHQR();
            const response = khqr.generateIndividual(individualInfo);
            const KHQRString = JSON.stringify(response.data.qr);
            const KHQR_md5 = JSON.stringify(response.data.md5);
            const KHQRString1 = KHQRString.replace(/^"(.*)"$/, "$1");
            setKHQRStringtest(KHQRString1);
            setMd5(KHQR_md5);

            wsRef.current = new WebSocket(`wss://apifarmreel.mmoshop.me/payment?md5=${KHQR_md5}&email=${email}`);

            wsRef.current.onmessage = (event) => {
                if (event.data === "verified") {
                    onClose();
                } else if (event.data === "closeModal") {
                    onClose();
                    setKhqrSelected(false);
                    setIsModalOpen(false);
                } else if (event.data.includes("Buykey:")) {
                    var check = event.data.split("Buykey:")[1].trim();
                    check = check.replace(/'/g, '"');

                    try {
                        var keyObject = JSON.parse(check);
                        var key = keyObject.buykey;
                        var get_telegram = keyObject.telgram;
                        wsRef.current.close();
                        settelegram(get_telegram);
                        setKey(key);
                    } catch (error) {
                        console.error("", error);
                    }
                }
            };
            return () => {
                if (wsRef.current) {
                    wsRef.current.close();
                }
            };
        }
    }, [khqrSelected]);

    useEffect(() => {
        if (key) {
            sendKeyToPyQt5(key);
        }
    }, [key]);

    const sendKeyToPyQt5 = (key) => {
        if (window.bridge) {
            window.bridge.process(key);
        } else {
            console.error("Bridge is not connected.");
        }
    };

    const PricingTable = () => {
        return (
            <div className="pricing-table">
                <h3 className='text-center text-black'>Choose Subscription Plan</h3>
                <div
                    className={`pricing-card border-solid border-2 p-2 rounded-lg ${selectedPlan === subscriptions["ONE_MONTH"] ? 'selected' : ''}`}
                    onClick={() => setSelectedPlan(subscriptions["ONE_MONTH"])}
                >
                    <div className="grid gap-4 grid-cols-2 items-center p-3 ">
                        <div className='text-start'>
                            <div className="pricing-header font-bold ">Monthly Plan</div>
                            <div className="pricing-price font-bold">$10.00 /Month</div>
                            <div className="pricing-subtitle">Perfect for starters</div>
                        </div>
                        <div>
                            <div className="pricing-features text-start">
                                <p className='text-start font-bold'><span>You Get</span></p>
                                <p>✔ 2 Time Change Key</p>
                                <p>✔ Phone Support</p>
                                <p>✔ LDPlayer Support</p>
                                <p>✔ Mobile App FARMREEL (soon)</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`pricing-card border-solid border-2 p-2 rounded-lg ${selectedPlan === subscriptions["THREE_MONTHS"] ? 'selected' : ''}`}
                    onClick={() => setSelectedPlan(subscriptions["THREE_MONTHS"])}
                >
                    <div className="grid gap-4 grid-cols-2 items-center p-3">
                        <div className='text-start'>
                            <div className="pricing-header font-bold">3Months Plan</div>
                            <div className="pricing-price font-bold">$30.00 /3Months<span className="extra-days">+15days</span> </div>
                            <div className="pricing-subtitle">Perfect for professional</div>
                        </div>
                        <div>
                            <div className="pricing-features text-start">
                                <p className='text-start font-bold'><span>You Get</span></p>
                                <p>✔ 6 Time Change Key</p>
                                <p>✔ Phone Support</p>
                                <p>✔ LDPlayer Support</p>
                                <p>✔ Mobile App FARMREEL (soon)</p>
                            </div>
                        </div>
                    </div>
                </div>

                <Button color="green" appearance="primary" className="subscribe-button w-full" onClick={onOpen} disabled={!selectedPlan}>Subscribe</Button>

            </div>
        );
    };


    return (
        <div className="App">
            <Routes>
                <Route path="/" element={
                    <div >
                        <PricingTable />
                        {
                            isOpen &&
                            <ChakraProvider>
                                <Modal isOpen={isOpen} isCentered onClose={() => onClose()} closeOnOverlayClick={false}>
                                    <ModalOverlay />
                                    <ModalContent style={{ backgroundColor: "rgb(255 255 255 / 95%)", borderRadius: '20px' }}>
                                        <ModalHeader >
                                            <div className=''>
                                                <div style={{ fontSize: '18px', fontWeight: 'bold' }}>
                                                    Payment method
                                                </div>
                                                <div style={{ fontSize: '14px' }}>
                                                    Select your preferred payment method
                                                </div>
                                            </div>
                                        </ModalHeader>
                                        <ModalCloseButton />
                                        <ModalBody className='payment-methods-modal justify-center'>
                                            <div className="email-input-container mb-4">
                                                {showInput && (
                                                    <div>
                                                        <Input
                                                            placeholder="Enter your email for the license key."
                                                            value={email}
                                                            onChange={handleEmailChange}
                                                            type="email"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            {!showQR && (
                                                <>
                                                    <div className={`payment-method ${selectedPaymentMethod === 'KHQR' ? 'selected' : ''}`} onClick={() => setSelectedPaymentMethod('KHQR')}>
                                                        <div className="payment-logo khqr-logo"></div> {/* You'll need to add styling for this logo */}
                                                        <div className="payment-details">
                                                            <div className="card-title">Pay with KHQR</div>
                                                        </div>
                                                        <div className={`selection-indicator ${selectedPaymentMethod === 'KHQR' ? 'selected' : ''}`}></div>
                                                    </div>
                                                    {disableStripe ? (
                                                        <div className="payment-method disabled">
                                                            <div className="payment-logo visa-logo"></div>
                                                            <div className="payment-details">
                                                                <div className="card-title">Pay with Stripe</div>
                                                            </div>
                                                            <div className="selection-indicator"></div>
                                                        </div>
                                                    ) : (
                                                        <div className={`payment-method ${selectedPaymentMethod === 'VISA' ? 'selected' : ''}`} onClick={() => setSelectedPaymentMethod('VISA')}>
                                                            <div className="payment-logo visa-logo"></div>
                                                            <div className="payment-details">
                                                                <div className="card-title">Pay with Stripe</div>
                                                            </div>
                                                            <div className={`selection-indicator ${selectedPaymentMethod === 'VISA' ? 'selected' : ''}`}></div>
                                                        </div>
                                                    )}
                                                    <Button
                                                        color="green" appearance="primary" className='w-full button-color'
                                                        isDisabled={!isEmailValid}
                                                        onClick={handleSubscribeClick}
                                                    >
                                                        Subscribe
                                                    </Button>
                                                </>
                                            )}
                                            <div className='flex justify-center items-center flex-col'>
                                                {showQR && selectedPaymentMethod === 'KHQR' && (
                                                    <>
                                                        <div className='flex justify-center items-center'>
                                                            <QRCode value={KHQRStringtest} size={200} bgColor="transparent" />
                                                        </div>
                                                        {key && (
                                                            <div className="text-center mt-4 space-y-5">
                                                                <div className=''>
                                                                    <p className='price-font_model'>
                                                                        <span style={{ color: 'green' }}>{key}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>
                            </ChakraProvider>
                        }
                    </div>
                } />
            </Routes>
        </div>
    );
}

export default PaymentPageComponent;
