/* global AbaPayway */
import './App.css';
import logo from "./images/logo1.png";
import message_icon from "./images/message_icon.png";
import logo_bank from "./images/khqr_bank.png";
import { Button, Input, Panel, Grid, Row, Col } from 'rsuite';
import './PricingTable.css'; // Import your CSS for styling
import KHQR from "./images/ABA_QR.png";
import BG_KHQR from "./images/bg-khqr.png";

import 'rsuite/dist/rsuite.min.css';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, ModalFooter, Icon } from '@chakra-ui/react';
import { ChakraProvider } from "@chakra-ui/react"
import QRCode from 'qrcode.react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BakongKHQR, khqrData, IndividualInfo, MerchantInfo, SourceInfo } from "bakong-khqr";
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';
import MainUI from './main';
import PaymentPage from './PaymentPage';
import CryptoJS from 'crypto-js';
import SubscriptionOptions from './SubscriptionOptions';

const subscriptions = {
  "ONE_MONTH": {
    name: "1 Month Subscription",
    price: 9.99,
  },
  "THREE_MONTHS": {
    name: "3 Months Subscription",
    price: 29.99
  }
}

const PopupMessage = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='App'>
      <div className='flex justify-center items-center' style={{ width: "100vw", height: "100vh" }}>
        <div className='bg-slate-50 bg-opacity-90 rounded-3xl shadow-lg p-10 space-y-5' style={{ backgroundImage: `url("https://mmoshop.me/static/media/background-table.55cef0dc9e019c4187c2.svg")`, backgroundColor: "rgb(255 255 255 / 90%)" }}>
          <div className="flex justify-center items-center">
            <img src={message_icon} />
          </div>
          <h2 className='font-bold font-mono'>Thank you for your subscription!</h2>
          <p className='font-bold'>Your payment is successful! Please check your email for confirmation.</p>
          <Button className='opacity-80' color="green" appearance="primary" onClick={() => window.location.href = "/"}>Go Home</Button>
        </div>
      </div>
    </div>
  );
};




const PayWayCheckout = ({ email, amount, isEmailValid, onClose }) => {
  const encodedEmail = encodeURIComponent(email);
  const baseUrl = 'https://apifarmreel.mmoshop.me/api/payment-success';
  const returnUrl = `${baseUrl}`;

  const [formData, setFormData] = useState({
    req_time: Date.now().toString(),
    merchant_id: 'farmreel',
    tran_id: Date.now().toString(),
    amount: amount.toString(),
    firstName: 'Heang',
    lastName: 'Lyhour',
    email: email,
    phone: '+85510888461',
    payment_option: 'abapay_khqr',
    items: '',
    currency: 'USD',
    return_url: returnUrl,
    continue_success_url: 'https://farmreel.mmoshop.me/payment_status',
    return_params: JSON.stringify({ email: email, amount: amount, req_time: Date.now().toString() }),
    custom_fields: '',

  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      email: email,
      return_params: JSON.stringify({ email: email, amount: amount, req_time: Date.now().toString() })
    }));
  }, [email]);

  const generateHash = () => {
    const secretKey = '4e2ca2de-0bec-4220-98ad-d31f7d3d1ad5';
    const dataString =
      formData.req_time +
      formData.merchant_id +
      formData.tran_id +
      formData.amount +
      formData.items +
      formData.firstName +
      formData.lastName +
      formData.email +
      formData.phone +
      formData.payment_option +
      formData.return_url +
      formData.continue_success_url +
      formData.currency +
      formData.custom_fields +
      formData.return_params;

    const hash = CryptoJS.HmacSHA512(dataString, secretKey);
    return CryptoJS.enc.Base64.stringify(hash);
  };



  const hash = generateHash();

  useEffect(() => {
    const loadPayWayScript = () => {
      if (!document.querySelector(`script[src="https://checkout.payway.com.kh/plugins/checkout2-0.js"]`)) {
        const script = document.createElement('script');
        script.src = "https://checkout.payway.com.kh/plugins/checkout2-0.js";
        script.async = true;
        script.onload = () => {
          // console.log('PayWay script loaded');
        };
        script.onerror = () => {
          // console.error('Failed to load PayWay script');
        };
        document.body.appendChild(script);
      }
    };
    loadPayWayScript();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isEmailValid) {
      alert('The email is not valid.');
      return;
    }

    if (typeof AbaPayway !== 'undefined') {
      AbaPayway.checkout();
      onClose();
      console.error('Paid Payway');
    } else {
      console.error('AbaPayway is not defined');
    }
  };


  return (
    <div className="">
      <form
        id="aba_merchant_request"
        action="https://checkout.payway.com.kh/api/payment-gateway/v1/payments/purchase"
        method="post"
        target="aba_webservice"
        encType="multipart/form-data"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="hash" value={hash} id="hash" />
        <input type="hidden" name="req_time" value={formData.req_time} />
        <input type="hidden" name="merchant_id" value={formData.merchant_id} />
        <input type="hidden" name="tran_id" value={formData.tran_id} />
        <input type="hidden" name="amount" value={formData.amount} />
        <input type="hidden" name="firstname" value={formData.firstName} />
        <input type="hidden" name="lastname" value={formData.lastName} />
        <input type="hidden" name="email" value={formData.email} />
        <input type="hidden" name="phone" value={formData.phone} />
        <input type="hidden" name="payment_option" value={formData.payment_option} className="payment_option" />
        <input type="hidden" name="currency" value={formData.currency} />
        <input type="hidden" name="return_url" value={formData.return_url} />
        <input type="hidden" name="continue_success_url" value={formData.continue_success_url} />
        <input type="hidden" name="custom_fields" value={formData.custom_fields} />
        <input type="hidden" name="return_params" value={formData.return_params} />

        <button
          id="checkout_button"
          type="submit"
          style={{
            backgroundColor: '#7ABC43',
            color: 'white',
            width: '100%',
            padding: '8px',
            fontSize: '14px',
            borderRadius: '8px',
            border: 'none',
            cursor: isEmailValid ? 'pointer' : 'not-allowed',
            textAlign: 'center',
            opacity: isEmailValid ? 1 : 0.6,
            marginTop: '20px'
          }}
          onMouseOver={(e) => isEmailValid && (e.target.style.backgroundColor = '#65a836')}
          onMouseOut={(e) => isEmailValid && (e.target.style.backgroundColor = '#7ABC43')}
          disabled={!isEmailValid}
        >
          PAY NOW
        </button>
      </form>
    </div>
  );
};

function App() {

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [showInput, setShowInput] = useState(true);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [email, setEmail] = useState('');

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };



  const handleEmailChange = (value) => {
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false); // State to control the popup visibility

  const handlePaymentSuccess = () => {
    setIsSuccessModalOpen(true); // Show the popup when payment is successful
  };

  const handleSuccessModalClose = () => {
    setIsSuccessModalOpen(false); // Close the popup
  };

  // const NewPageComponent = () => {
  //   return (
  //     <div className='hidden lg:block'>
  //       <div className='flex justify-center items-center text-center relative' style={{ height: '100vh', backgroundColor: 'black' }}>
  //         <span className="image-content__decor absolute inset-0 flex items-center justify-center back ">
  //           <img decoding="async" className="image-content__decor-stars" src="https://wonderdynamics.com/wp-content/themes/wonder-dynamics-theme-oLXBdN/assets/images/image-content-stars.png" alt="decoration" />
  //         </span>
  //         <div className='w-full max-w-6xl space-y-20 relative z-10'>
  //           <h2 className='font-text'>Click To Watch Setup Farm Reel</h2>
  //           <div style={{ position: 'relative', }}>
  //             <iframe id="ytplayer" type="text/html" width="100%" height="655"
  //               src="https://www.youtube.com/embed/?listType=playlist&list=PLnYnulmN4GyvYkqgHitCJYhbBlecpxzUv"
  //               frameborder="0" allowfullscreen></iframe>
  //           </div>
  //           <script src="https://player.vimeo.com/api/player.js"></script>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const ListSoftware = () => {
    return (
      <div className='hidden lg:block'>
        <div className='flex justify-center items-center text-center relative' style={{ height: '100vh', backgroundColor: "rgb(255 255 255 / 50%)" }}>
          <span className="image-content__decor absolute inset-0 flex items-center justify-center back ">
            <img decoding="async" className="image-content__decor-stars" src="https://wonderdynamics.com/wp-content/themes/wonder-dynamics-theme-oLXBdN/assets/images/image-content-stars.png" alt="decoration" />
          </span>
          <div className='w-full max-w-6xl space-y-20 relative'>
            <h2 className=''>How To setup Software Farm Reel</h2>

            <div className='flex item-center justify-center h-full' >

              <table className="table-auto border border-black bg-green-400 rounded-2xl w-full" style={{ backgroundImage: `url("https://mmoshop.me/static/media/background-table.55cef0dc9e019c4187c2.svg")`, backgroundColor: "rgb(255 255 255 / 80%)", maxWidth: "60vh", overflow: "hidden" }}>
                <thead className=''>
                  <tr>
                    <th className="border border-white p-3 min-w-[210px]">STATUS</th>
                    <th className="border border-white p-3 min-w-[510px]">SETUP TOOL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-white p-3">STEP 1</td>
                    <td className="border border-white p-3 flex item-start space-x-2">
                      <Button color="green" appearance="primary" className='w-full button-color' onClick={() => window.open('https://wnc1c-my.sharepoint.com/:u:/g/personal/hearhour_wnc1c_onmicrosoft_com/EQunILY93hhMhwonZDNHsFYBsscQemgao2H3kmx0_bRN1A?e=OwDFbN', '_blank')}> Download LDPlayer</Button>
                      <Button color="green" appearance="primary" className='w-full button-color' onClick={() => window.open('https://www.dropbox.com/scl/fi/bupaok0lwvqb07747uixi/FarmReel-Installer.exe?rlkey=z40domh5rjiyz1uydquas6b4b&st=hqu5obo7&dl=1', '_blank')}> Download Tool</Button>

                    </td>

                  </tr>
                  <tr>
                    <td className="border border-white p-3">STEP 2</td>
                    <td className="border border-white p-3 flex item-start">Setup environment : 1 - 4</td>

                  </tr>
                  <tr>
                    <td className="border border-white p-3">STEP 3</td>
                    <td className="border border-white p-3 flex item-start">Run Tool FarmReel</td>

                  </tr>
                </tbody>
              </table>

            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>

          </div>
          <div id="bottom1"></div>
          {/* <span
            data-scroll-to="#image-logos"
            id="bottom1"
            onClick={() => scrollToBottom(newPageComponentRef)}
            className="js-scroll-down home-hero__scroll-down"
          >
            <span></span>
          </span> */}
        </div>


      </div>
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const wsRef = useRef(null)
  const [KHQRStringtest, setKHQRStringtest] = useState('');
  const [key, setKey] = useState("")
  const [md5, setMd5] = useState("")
  const [telgram, settelegram] = useState("")
  const [info, setInfo] = useState(null)
  const [isOpen, setIsOpen] = useState(false);
  const [khqrSelected, setKhqrSelected] = useState(false);


  useEffect(() => {
    // Automatically open the popup when the page loads
    setIsPopupVisible(true);
  }, []);

  const closePopup = () => {
    setIsPopupVisible(false);
    setSelectedPaymentMethod(null); // Reset any payment method selection if needed
    document.activeElement.blur();
  }

  const handleModalClose = () => {
    onClose();
    setEmail(''); // Reset email field
    document.activeElement.blur();
  };

  const handleButtonClick = () => {
    if (isPopupVisible) {
      closePopup();
    } else {
      // Your button click logic here
    }
  };

  const handleNextClick = () => {
    if (!isEmailValid) {
      alert('The email is not valid.');
      return;
    }

    setShowInput(false);
    console.log('Next button clicked with valid email:', email);

    if (selectedPaymentMethod === 'PayWay') {
      setIsOpen(true);
    } else if (selectedPaymentMethod === 'VISA') {
      const amountInCents = info?.price;
      fetch(`https://apifarmreel.mmoshop.me/api/create-stripe-session?amount=${amountInCents}&email=${email}`, {
        method: 'POST',
      }).then(response => response.json()).then(data => {
        window.location.href = `${data.sessionURL}`;
      }).catch(error => console.error('Error:', error));
    }
  };

  const onOpen = (subscription) => {
    setInfo(subscription);
    setSelectedPaymentMethod('PayWay');
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
    setShowInput(true); // Reset email input visibility
  };



  useEffect(() => {
    if (khqrSelected) {
      setKey('');
      // setKey('FARMREEL1YTTHIW1NIR5Q8I2');
      // settelegram('https://t.me/+_RnNmwbXT-9lMjY1');
      const { BakongKHQR, khqrData } = require("bakong-khqr");
      const optionalData = {
        currency: khqrData.currency.usd,
        amount: info?.price,
        mobileNumber: "85510888461",
        storeLabel: "FARM REEL",
        terminalLabel: "MMOSHOP_Invoices",
        purposeOfTransaction: "Tool FARMREEL",
        languagePreference: "km",
        merchantNameAlternateLanguage: "Heang",
        merchantCityAlternateLanguage: "Lyhour",
        upiMerchantAccount: "",
      };

      const individualInfo = new IndividualInfo(
        "heang_lyhour@aclb",
        "FARM REEL",
        "PHNOM PENH",
        optionalData
      );

      const khqr = new BakongKHQR();
      const response = khqr.generateIndividual(individualInfo);
      const KHQRString = JSON.stringify(response.data.qr);
      const KHQR_md5 = JSON.stringify(response.data.md5);
      const KHQRString1 = KHQRString.replace(/^"(.*)"$/, "$1");
      setKHQRStringtest(KHQRString1);
      setMd5(KHQR_md5)

      // wsRef.current = new WebSocket(`ws://127.0.0.1:7000/payment?md5=${KHQR_md5}&email=${email}`);
      wsRef.current = new WebSocket(`wss://apifarmreel.mmoshop.me/payment?md5=${KHQR_md5}&email=${email}`)

      wsRef.current.onmessage = (event) => {
        if (event.data === "verified") {
          onClose();

        } else if (event.data === "closeModal") {
          onClose();
          setKhqrSelected(false);
          setIsModalOpen(false);
        } else if (
          event.data.includes("Buykey:")) {
          var check = event.data.split("Buykey:")[1].trim()
          check = check.replace(/'/g, '"');

          try {
            var keyObject = JSON.parse(check);
            var key = keyObject.buykey;
            var get_telegram = keyObject.telgram;
            wsRef.current.close();
            settelegram(get_telegram);
            setKey(key);
          } catch (error) {
            console.error("", error);
          }

        }
      }
      return () => {
        if (wsRef.current) {
          wsRef.current.close()
        }
      }

    }



  }, [khqrSelected])



  const listSoftwareRef = useRef(null);
  const newPageComponentRef = useRef(null);
  const [oldkey, setoldkey] = useState("")
  const [newkey, setnewey] = useState("")
  const [display, setdisplay] = useState("")
  const proxyServerUrl = 'https://apifarmreel.mmoshop.me';
  // const proxyServerUrl = 'https://127.0.0.1:8000';
  const image = 'https://images.pexels.com/photos/17483908/pexels-photo-17483908/free-photo-of-an-artist-s-illustration-of-artificial-intelligence-ai-this-image-represents-the-concept-of-artificial-general-intelligence-agi-and-its-potential-for-medicine-and-biology-it-was-crea.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
  const image2 = 'https://images.pexels.com/photos/17483909/pexels-photo-17483909/free-photo-of-an-artist-s-illustration-of-artificial-intelligence-ai-this-image-represents-the-concept-of-artificial-general-intelligence-agi-and-the-potential-of-generative-ai-it-was-created-by-d.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'


  const [paymentSuccess, setPaymentSuccess] = useState(false);


  async function changeKey() {
    try {
      const response = await axios.get(`${proxyServerUrl}/farmreel/changekey?old_license=${oldkey}&new_license=${newkey}`, {
        headers: {
          'accept': 'application/json'
        }
      });
      setdisplay(response.data.detail);
    } catch (error) {
      setdisplay("Too Many Requests");
    }
  }
  const isDisabled = oldkey === "" || newkey === "";

  const scrollToBottom = (ref) => {
    const scrollY = ref.current.offsetTop;
    window.scroll({
      top: scrollY,
      behavior: 'smooth'
    });
  };

  const scrollToNewPageComponent = () => {
    newPageComponentRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  function SuccessComponent() {
    const location = useLocation();

    // Helper function to parse the query parameters
    const parseQueryParams = (query) => {
      const params = new URLSearchParams(query);
      return {
        key: params.get('key'),
        telegram: params.get('telegram')
      };
    };

    // Extracting key and telegram from URL
    const { key, telegram } = parseQueryParams(location.search);
  }

  const handleModalClosage = () => {
    onClose()
    setEmail("")
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/payment_status" element={<div className="App"><PopupMessage /></div>} />
          <Route path="/success" element={<SuccessComponent />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/main" element={<MainUI />} />
          <Route path="/payment_app_hearhour" element={<PaymentPage onOpen={onOpen} subscriptions={subscriptions} />} />
          <Route path="/" element={
            <div>
              <div className="App" >
                <div className='h-20 flex justify-around items-center text-center '>
                  <div className="cursor-pointer" >
                    <img className='' src={logo} alt="" width={220} />
                  </div>
                  <div className='flex space-x-8 '>
                    <div className="cursor-pointer hidden lg:block" onClick={() => setSelectedPaymentMethod('PayWay')}>
                      <h5 className='meun-text'> SETUP TOOL</h5>
                    </div>
                    <div className="cursor-pointer" onClick={() => window.open('https://t.me/lduploadreel', '_blank')}>
                      <h5 className='meun-text'> Contact</h5>
                    </div>
                  </div>
                </div>
                {/* <div className='content'>
          <h1>Select Your Subscription Plan</h1>
          <SubscriptionOptions onSubscribe={handleSubscription} />
        </div> */}
                <div className='grid grid-cols-12 ' style={{ minHeight: "95vh", maxWidth: '140vh' }}>
                  <div className='col-span-12 lg:col-span-6 p-5 flex justify-center items-center '>
                    <div className=' space-y-5 flex-col justify-center items-center w-full'>
                      <div className='title-main flex justify-center items-center ' >
                        <div className='flex justify-center items-center space-x-3 w-full' style={{ maxWidth: "50vh", overflow: "hidden" }}>
                          <div className='bg-white h-[500px] w-full rounded-2xl text-center shadow-lg hidden lg:block' style={{ backgroundColor: "rgb(255 255 255 / 80%)", maxWidth: "50vh", overflow: "hidden" }}>
                            <di>
                              <img className='w-full h-[180px] rounded-2xl p-2 object-cover ' src={image} alt="" width="200px" />
                              <div className='space-y-14 price-font image-content__decor-stars' style={{
                                backgroundSize: 'cover',
                                backgroundPosition: 'right bottom',
                                backgroundRepeat: 'no-repeat',
                                backgroundImage: `url(${BG_KHQR})`,
                              }}>

                                <div className="pricing-price font-bold font-mono"><h3>$9.99 /Month</h3> </div>
                                <div className='justify-center flex items-center '>
                                  <p className='p-2 rounded-2xl box-p'>Unlimited LDPlayer Support</p>
                                </div>
                                <p>2 Time Change Key</p>
                                <div className='p-2'>
                                  <Button className='w-full' style={{ backgroundColor: '#eeeded', color: 'black', fontSize: '12px', borderRadius: '12px' }}
                                    onMouseOver={(e) => { e.target.style.backgroundColor = '#7ABC43' }}
                                    onMouseOut={(e) => { e.target.style.backgroundColor = '#eeeded' }}
                                    onClick={() => onOpen(subscriptions["ONE_MONTH"])}
                                  >1 month subscription</Button>
                                </div>
                              </div>
                            </di>
                          </div>

                          <div className='bg-white h-[500px] w-full rounded-2xl text-center shadow-md hidden lg:block ' style={{ backgroundColor: "rgb(255 255 255 / 80%)", maxWidth: "50vh", overflow: "hidden" }}>
                            <img className='w-full h-[180px] rounded-2xl p-2 object-cover' src={image2} alt="" width="200px" />
                            <div className='space-y-14 price-font image-content__decor-stars' style={{
                              backgroundSize: 'cover',
                              backgroundPosition: 'right bottom',
                              backgroundRepeat: 'no-repeat',
                              backgroundImage: `url(${BG_KHQR})`,
                            }}>
                              {/* <h3>$29.99 USD</h3> */}
                              <div className="pricing-price font-bold font-mono"><h3>$29.99 /3Months</h3><span className="extra-days">+15days</span> </div>
                              <div className='justify-center flex items-center '>
                                <p className='p-2 rounded-2xl box-p'>Unlimited LDPlayer Support</p>
                              </div>
                              <p>6 Time Change Key</p>
                              <div className='p-2'>
                                <Button className='w-full buy-button' style={{ backgroundColor: '#eeeded', color: 'black', fontSize: '12px', borderRadius: '12px' }}
                                  onMouseOver={(e) => { e.target.style.backgroundColor = '#7ABC43' }}
                                  onMouseOut={(e) => { e.target.style.backgroundColor = '#eeeded' }}
                                  onClick={() => onOpen(subscriptions["THREE_MONTHS"])}
                                >3 months subscription</Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='title-main flex justify-center items-center'>
                        <div className="space-y-5 p-5 rounded-2xl text-center shadow-2xl w-full h-[200px]" style={{ backgroundImage: `url("https://mmoshop.me/static/media/background-table.55cef0dc9e019c4187c2.svg")`, backgroundColor: "rgb(255 255 255 / 90%)", maxWidth: "50vh", overflow: "hidden" }}>
                          <div className='flex items-center justify-center h-full'>
                            <span className='w-full space-y-5'>
                              <p className='title-text'>Change License Key</p>
                              <div className='flex space-x-4'>
                                <Input value={oldkey} placeholder='Old License' onChange={(e) => setoldkey(e)} />
                                <Input value={newkey} placeholder='New License' onChange={(e) => setnewey(e)} />
                              </div>
                              <div className=''>
                                <Button color="green" appearance="primary" className='w-full button-color' onClick={changeKey} disabled={isDisabled}>Change Key</Button>
                              </div>
                              <p style={{ fontSize: "14px", fontWeight: "600", color: display === "Change success" ? "green" : (display === "Change limit exceeded" || display === "Too Many Requests" ? "red" : "inherit") }}>{display}</p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div ref={listSoftwareRef}>
                    <ListSoftware />
                  </div>
                  {/* <div ref={newPageComponentRef}>
                    <NewPageComponent />
                  </div> */}
                  <div id="bottom"></div>
                  <span
                    data-scroll-to="#image-logos"
                    id="bottom"
                    onClick={() => scrollToBottom(listSoftwareRef)}
                    className="js-scroll-down home-hero__scroll-down"
                  >
                    <span></span>
                  </span>
                </div>

                {isOpen && (
                  <ChakraProvider>
                    <Modal isOpen={isOpen} isCentered onClose={handleModalClosage} closeOnOverlayClick={false}>
                      <ModalOverlay
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0.6)", // Semi-transparent overlay
                        }}
                      />
                      <ModalContent
                        style={{
                          backgroundColor: "rgb(255 255 255 / 95%)", // Modal background
                          borderRadius: '20px',
                          zIndex: 1001, // Ensure it appears above the background
                        }}
                      >
                        <ModalHeader>
                          <div>
                            <div style={{ fontSize: '18px', fontWeight: 'bold' }}>Enter Your Email</div>
                            <div style={{ fontSize: '14px' }}>Please enter your email to receive the license key.</div>
                          </div>
                        </ModalHeader>

                        <ModalCloseButton />

                        <ModalBody className='payment-methods-modal justify-center'>
                          <div className="email-input-container">
                            {showInput && (
                              <div>
                                <Input
                                  placeholder="Enter your email for the license key."
                                  value={email}
                                  onChange={(value) => handleEmailChange(value)}
                                  type="email"
                                />
                              </div>
                            )}
                          </div>
                          {selectedPaymentMethod === 'PayWay' && (
                            <PayWayCheckout email={email} amount={info?.price} isEmailValid={isEmailValid} onClose={onClose} />
                          )}
                        </ModalBody>

                        <ModalFooter />
                      </ModalContent>
                    </Modal>
                  </ChakraProvider>

                )}
              </div>



              <footer style={{ backgroundColor: '#f8f9fa', padding: '10px 20px', }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p style={{ fontSize: '14px', color: '#555', margin: 0 }}>© {new Date().getFullYear()} FARMREEL. All rights reserved.</p>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '10px', fontSize: '14px', color: '#555' }}>We accept:</span>
                    <img src={logo_bank} alt="Visa" style={{ height: '30px', marginRight: '10px' }} />
                  </div>
                </div>
              </footer>
            </div>
          } />
        </Routes>
      </div>
    </Router>
  );
}




export default App;
