import React from 'react';
import './Main.css';
import PC from "./images/monitor.png";
import windowsIcon from "./images/window.svg"; // Import the window.svg

function Main() {
    return (
        <div className="">
            <div className="download-section">
                <h1>Download Termius for Windows</h1>
                <button className="download-button">Download .EXE</button>
                <div className="image-container">
                    <img src={PC} alt="Laptop" className="centered-image" />
                </div>
            </div>
            <div className='detail'>
                <div className="mobile-section">
                    {/* <h2>Try Termius for mobile</h2>
                    <p>Enjoy the secure sync and access your servers on the go with Termius mobile app for iOS and Android.</p>
                    <div className="mobile-links">
                        <img src="/path/to/qr-code-ios.png" alt="QR Code iOS" />
                        <img src="/path/to/qr-code-android.png" alt="QR Code Android" />
                    </div>
                    <div className="app-links">
                        <img src="/path/to/app-store.png" alt="App Store" />
                        <img src="/path/to/google-play.png" alt="Google Play" />
                    </div> */}
                <div className="download-links grid gap-6 items-center justify-center">
                    <div className="download-option">
                        <img src="/path/to/macos-icon.png" alt="macOS" />
                        <p>LDPlayer</p>
                        <button>Download .DMG</button>
                    </div>
                    <div className="download-option">
                        
                        <img src={windowsIcon} alt="Windows" /> 
                        <p>Window</p>
                        <button>Download .EXE</button>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}

export default Main;
